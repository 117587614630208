import React, { useState } from "react";
import { graphql } from "gatsby";
import { Builder, builder, BuilderComponent } from "@builder.io/react";
import Link from "../components/Link/Link";

builder.init(`5d8fedbe34c54b268774c5dc6c49b056`);

function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;

  return (
    <>
      <BuilderComponent renderLink={Link} name="page" content={page} />
    </>
  );
}

export default PageTemplate;

export const PageQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      page(target: { urlPath: $path }, limit: 1, options: { cachebust: true }) {
        content
      }
    }
  }
`;

// export const MusicViewer = () => {
//   const defaultSong = { title: "", url: "", description: "" };

//   const [songList, setSongList] = useState([]);
//   const [genreSelected, setGenreSelected] = useState("");
//   const [songSelected, setSongSelected] = useState(defaultSong);
//   const [songIndex, setSongIndex] = useState("");

//   function selectGenre(index) {
//     setSongList(index);
//     setGenreSelected(true);
//   }

//   function selectSong(index, song) {
//     setSongSelected(song);
//     setSongIndex(index);
//   }

//   function getList(genreIndex) {
//     return (
//       <>
//         {MusicConfig[genreIndex].playlist.map((song, index) => (
//           <S.Song
//             className={songIndex === index ? "selectedSong" : false}
//             key={index}
//             onClick={(e) => selectSong(index, song)}
//           >
//             {song.title}
//           </S.Song>
//         ))}
//       </>
//     );
//   }

//   return (
//     <S.Main>
//       <S.MusicHeader>Music</S.MusicHeader>
//       <S.GenreContainer>
//         {MusicConfig.map((mConfig, index) => (
//           <S.Genre
//             className={songList === index ? "selectedGenre" : false}
//             key={index}
//             onClick={() => selectGenre(index)}
//           >
//             {mConfig.genre}
//           </S.Genre>
//         ))}
//       </S.GenreContainer>
//       <S.GenreContent>
//         <S.MusicListContainer>
//           <S.Header>Tracks on this playlist</S.Header>
//           <S.GroupedSongs>{genreSelected && getList(songList)}</S.GroupedSongs>
//         </S.MusicListContainer>
//         <S.SelectedSongContainer>
//           <S.Header>Now Playing</S.Header>
//           <S.Title>{songSelected.title}</S.Title>
//           <S.Description>{songSelected.description}</S.Description>

//           {/* <ReactPlayer url='../songs/The HU - Sad But True.mp3' width="100%" height="100%" controls={true} /> */}
//           <S.StyledReactPlayer
//             url={songSelected.url}
//             width="100%"
//             height="fit-content"
//             controls={true}
//             config={{
//               file: {
//                 forceAudio: true,
//                 controlsList: "nodownload",
//               },
//             }}
//             controlslist="nodownload"
//             noDownload={true}
//             nofullscreen
//             noremoteplayback
//           />
//         </S.SelectedSongContainer>
//       </S.GenreContent>
//     </S.Main>
//   );
// };
// Builder.registerComponent(MusicViewer, {
//   name: "MusicViewer",
//   inputs: [{ name: "title" }],
// });
